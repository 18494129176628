import { mapActions, mapGetters } from 'vuex'
import validation from '@/mixins/validation'

export default {
  name: 'sing-in',
  mixins: [validation],
  data() {
    return {
      payload: {
        login: '',
        password: ''
      },
      valid: false
    }
  },
  computed: {
    ...mapGetters({
      config: 'setting/config',
      loading: 'auth/loadingSingIn',
      isAdmin: 'profile/isAdmin',
    })
  },
  methods: {
    ...mapActions({
      singIn: 'auth/AUTH_LOGIN',
      logout: 'auth/AUTH_LOGOUT',
      fetchUserData: 'profile/FETCH_USER_DATA'
    }),
    submit() {
      this.$refs.form.validate()
      if (this.valid) {
        this.singIn(this.payload)
          .then(async () => {
            await this.fetchUserData()
            if (!this.isAdmin) {
              this.logout()
              return
            }
            this.$toasted.success(this.$t('successAuth'));
            this.$router.push({ name: 'courses' });
          }).catch(error => {
            if (error.response !== undefined) {
              this.$toasted.error(error.response.data.error.message_key);
            }
            throw error;
          })
      }
    }
  }
}
